import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/tacticalImg"
import Hero from "../components/hero"
import Cta from "../components/cta"

const SoinsVisageStyling = styled.div`
  .grid {
    margin-bottom: var(--medium);
  }
  @media (max-width: 640px) {
    .grid {
      grid-row-gap: var(--medium);
    }
  }
`

const SoinsVisage = ({ setShowCta, cta }) => {
  const [showAcne, setShowAcne] = useState(false)
  return (
    <Layout>
      <SEO title="Soins du Visage" />
      <Hero title="Soins du Visage" hero="soins-visage-hero.jpg" />
      <SoinsVisageStyling className="container highlow">
        <div className="grid">
          {/* Soins Du Visage Essentiel */}
          <div className="col-md-6 make-room-medium">
            <Image
              src="soins-visage-homme.jpg"
              alt="Soins Du Visage Essentiel"
            />
          </div>
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "0 var(--large)" }}
          >
            <h2 style={{ textAlign: "left" }}>Soins Du Visage Essentiel</h2>
            <p>
              Pour un visage radieux et une peau net et purifiée, il est
              essentiel d'effectuer régulièrement des nettoyages profonds de la
              peau.
            </p>
            <p>
              Le soin du visage avec utilisation de vapozone vous permet de
              retrouver une peau purifiée tout en éliminant les comédons (points
              noirs) présents sur l'ensemble de votre visage.
            </p>
          </div>
        </div>
        <div className="grid">
          {/* Soins du visage pour peaux grasses ou acnéiques */}
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "0 var(--large)" }}
          >
            <h2 style={{ textAlign: "left" }}>
              Soins du visage pour peaux grasses ou acnéiques
            </h2>
            <p>
              L’utilisation de l’appareil faciale à haute fréquence aide à
              resserrer les pores et à cicatriser la peau.
            </p>
            <p>
              • DE LA MEILLEURE QUALITÉ: c'est à travers le tube d'électrode en
              verre pour produire un courant haute fréquence, et appliquer sur
              la peau, donc, obtenir le résultat du traitement. Il peut
              accélérer la circulation sanguine, renforcer l'activité
              lymphatique, tuer les bactéries et les virus, stimuler la
              circulation, éliminer l'acné et les boutons, favorise également la
              croissance des cheveux, prévient et guérit la chute des cheveux et
              la perte de couleur des cheveux, et améliore la sécrétion et la
              valeur des pH. Renforce le métabolisme cellulaire. Il aide à
              nourrir la peau.
            </p>
            <p>
              <button
                className="learn-more"
                onClick={e => {
                  setShowAcne(!showAcne)
                }}
              >
                Qu’est-ce que l’acné?
              </button>
            </p>
          </div>
          <div className="col-md-6 make-room-medium vertical-center mobile-first">
            <Image
              src="visage-acne.jpg"
              alt="Soins du visage pour peaux grasses ou acnéiques"
            />
          </div>
        </div>
        {/* Qu’est-ce que l’acné? */}
        {showAcne && (
          <div className="grid">
            <div
              className="col-md-6 vertical-center"
              style={{ padding: "0 var(--large)" }}
            >
              <h3>Qu’est-ce que l’acné?</h3>
              <p>
                Pour bien utiliser les huiles essentielles (HE) et plantes «
                anti-acné », il faut d’abord comprendre ce qu’est l’acné et d’où
                il vient.
                <br />
                L’acné est une maladie chronique du follicule pilo-sébacé. A la
                racine des poils que nous avons sur tout le corps, se trouve une
                petite glande qui produit le sébum. Chez les adolescents
                souffrant d’acné, cette glande produit trop de sébum. La peau
                est grasse et luisante. A force de produire du sébum, le
                follicule se bouche, les cellules meurent, la kératine
                s’accumule et un comédon se forme. C’est ce que l’on appelle les
                « points noirs ». Parfois une bactérie (Propionibacterium acnés)
                prolifère dans le sébum. On se retrouve alors avec un bouton
                inflammatoire (rouge, gonflé et qui fait mal): « les papules
                rouges ».
              </p>
              <p>
                Et parfois même, si on y touche trop, et qu’on infecte le
                bouton, ce sont alors les « pustules jaunes et purulentes ».
                Deux types d’acné prédominent donc : • l’acné intentionnelle :
                majorité de points noirs • l’acné inflammatoire : majorité de
                papules et de pustules L’acné survient le plus souvent chez les
                adolescents (changements hormonaux). Il est plus fréquent chez
                les garçons que chez les filles. En revanche à l’âge adulte plus
                de femmes sont touchées que d’hommes. La grosse différence entre
                l’acné de l’adolescent et celui de l’adulte : l’adolescent a des
                boutons et la peau grasse tandis que la femme adulte a des
                boutons mais une peau globalement plus sèche.
              </p>
              <p>
                Pour mieux vous aider à résoudre cette affection cutanée, il est
                préférable de rencontrer une de nos spécialistes de la peau afin
                de vous offrir le meilleur protocole de traitement adapté à vos
                besoins, puisqu’il existe 3 différents stades d’acné, chacun
                doit être traité différemment.
              </p>
            </div>
            <div className="col-md-6 vertical-center mobile-first">
              <Image src="acne-garcon.jpg" alt="Acne Garçon" />
            </div>
          </div>
        )}
        {/* Soins du visage anti-âge oxygénant et revitalisant */}
        <div className="grid">
          <div className="col-md-6 make-room-medium vertical-center">
            <Image
              src="visage-revit.jpg"
              alt="Soins du visage anti-âge oxygénant et revitalisant"
            />
          </div>
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "0 var(--large)" }}
          >
            <h2 style={{ textAlign: "left" }}>
              Soins du visage anti-âge oxygénant et revitalisant
            </h2>
            <p>
              <strong>Objectif beauté;</strong>
              <br />
              - Ce soin gorgé de vitamines, hydratant et oxygénant, va redonner
              souplesse, vitalité et douceur à votre peau. La peau est plus
              ferme, lumineuse comme régénérée. Programme beauté
              <br />- Doux nettoyage biologique du visage permettant d’éliminer
              les impuretés, il prépare la peau à recevoir les soins spécifiques
              grâce à des enzymes de papaye et d’ananas. Séquence
              sur-hydratation pour régénérer la peau grâce aux techniques de
              modelage spécifiques, plus massage.
            </p>
            <p>
              <strong>Actifs beauté ;</strong>
              <br />
              Le silicium organique va permettre de restaurer et relancer la
              production des fibres d’élastine et de collagène. La bardane
              antiseptique favorise l’élimination des imperfections de la peau,
              hydrate et oxygène. Votre peau est dynamisée.
            </p>
          </div>
        </div>
        {/* Soins du visage anti-âge, confort peau sensible et sèche */}
        <div className="grid">
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "0 var(--large)" }}
          >
            <h2 style={{ textAlign: "left" }}>
              Soins du visage anti-âge, confort peau sensible et sèche
            </h2>
            <p>
              <strong>Objectif beauté</strong>
              <br />
              Retrouvez le confort d’une peau bien hydratée et rebondie. Les
              actifs spécifiques viendront compenser les carences nutritives de
              la peau pour renforcer sa protection naturelle et éviter les
              sensations de tiraillement.
            </p>
            <p>
              <strong>Programme beauté</strong>
              <br />
              Doux nettoyage du visage biologique permettant d’éliminer les
              impuretés. Il prépare la peau à recevoir le masque lissant
              hydratant en dose unique pour une hygiène irréprochable. Les
              techniques de modelage spécifiques viendront repeupler et drainer
              l’ensemble du visage.
            </p>
            <p>
              <strong>Actifs beauté</strong>
              <br />
              Les micromolécules stimulent les cellules et restaurent la
              tonicité et l’élasticité de la peau. La marjolaine et le
              millepertuis calmeront les peaux les plus sensibles et assureront
              un confort et une hydratation optimale.
            </p>
          </div>
          <div className="col-md-6 make-room-medium vertical-center mobile-first">
            <Image
              src="visage-anti-age.jpg"
              alt="Soins du visage anti-âge, confort peau sensible et sèche"
            />
          </div>
          {/* Soins du visage anti-âge rougeurs diffuses */}
          <div className="col-md-6 make-room-medium vertical-center">
            <Image
              src="visage-rougeurs.jpg"
              alt="Soins du visage anti-âge rougeurs diffuses"
            />
          </div>
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "0 var(--large)" }}
          >
            <h2 style={{ textAlign: "left" }}>
              Soins du visage anti-âge rougeurs diffuses
            </h2>
            <p>
              <strong>Objectif beauté</strong>
              <br />
              Véritable pansement de douceur pour les zones irritées, ce soin va
              permettre à la peau de retrouver son équilibre et son élasticité.
              Les produits spécialement adaptés vont permettre l’amélioration de
              la microcirculation. Les rougeurs diffusées s’estompent, le teint
              redevient uniforme.
              <ul className="services">
                <li>
                  Doux nettoyage biologique du visage permettant d’éliminer les
                  impuretés. Lotion à base de millepertuis, marjolaine et arnica
                  pour calmer l’irritation et la rougeur.
                </li>
                <li>
                  Séquence sur-hydratation associée aux techniques de modelage
                  spécifiques pour réduire la sensation de tiraillement et
                  favoriser les échanges cellulaires.
                </li>
              </ul>
            </p>
            <p>
              <strong>Actifs beauté</strong>
              <br />
              <ul className="services">
                <li>
                  Les huiles de pépins de raisin et de framboise vont permettre
                  d’hydrater, protéger et de réduire les rougeurs diffuses par
                  sa richesse en vitamine E.
                </li>
                <li>
                  Votre peau retrouve confort, souplesse et les rougeurs sont
                  atténuées.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </SoinsVisageStyling>
      <Cta setShowCta={setShowCta} cta={cta} />
    </Layout>
  )
}

export default SoinsVisage
